.form-questions {
  height: 100%;
  display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
  display: -moz-box; /* Firefox 2.0-19 */
  display: -ms-flexbox; /* IE 10 */
  display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
  display: flex; /* Estándar */
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 0;
  font-family: $font-secondary;
  @include for-bp(tablet-switch) {
    max-height: 550px;
    height: 100%;
    padding: 0;
  }

  &.--align-end {
    // justify-content: flex-end;
  }

  &__fieldset {
    border: none;
    padding: 0;
    @include for-bp(mobile-md) {
      padding: 0 10px;
    }
    @include for-bp(tablet-switch) {
      padding: 0 50px;
    }
  }

  &__legend {
    padding-left: calc(20px + 22px);

    &.--small {
      padding-left: 0;
      //font-size: 20px;
      //font-weight: 300;
      //text-transform: none;
      text-align: left;
      max-width: 950px;
      //margin-bottom: 60px;
    }
  }

  &__options {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    flex-direction: column;
    gap: 22px;
    color: get-color(blue-2);
    font-size: 16px;
    font-weight: 300;
    line-height: 150%;

    @include for-bp(tablet-switch) {
      font-size: 20px;
    }
  }

  &__label {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    gap: 20px;
    -webkit-box-align: flex-start; /* Safari 3.1-6.0, Chrome 4-21 */
    -webkit-align-items: flex-start; /* Safari 6.1+, Chrome 21+ */
    -ms-flex-align: flex-start; /* IE 10 */
    align-items: flex-start;
    cursor: pointer;

    @include for-bp(mobile-md) {
      gap: 28px;
    }
  }

  &__input {
    //width: 22px;
    //height: 22px;
    //flex-shrink: 0;
    //margin-top: 5px;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__checkmark {
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    margin-top: 5px;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid get-color(gray-1);
    position: relative;

    &::after {
      content: '';
      position: absolute;
      display: none;
      left: 7px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid get-color(black);
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &.--checked {
      background-color: get-color(light-gray);
    }

    &.--checked::after {
      display: block;
    }
  }

  &__container {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    justify-content: center;
    flex-direction: column;
    gap: 50px;
    @include for-bp(tablet-switch) {
      flex-direction: row;
    }
    @include for-bp(tablet-lg) {
      gap: 150px;
    }

    .form-questions {
      &__options {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        @include for-bp(tablet-lg) {
          width: auto;
          margin-left: 0;
          margin-right: 0;
          // margin-top: 40px;
        }
      }
    }
  }

  &__figure {
    margin: 0;
    > img {
      margin: 0 auto;
      display: block;
    }
  }

  &__caption {
    margin: 10px auto 0;
    text-align: left;
    max-width: 400px;
    color: get-color(blue-2);
    font-family: $font-secondary;
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
    letter-spacing: -0.266px;
  }

  &.inputFromRight {
    opacity: 0;
    -webkit-animation: fadeIn 1.7s forwards;
    animation: fadeIn 1.7s forwards;
  }

  &.inputFromRight &__label {
    .form-questions__checkmark {
      opacity: 0;
      -webkit-animation: fadeIn 0.7s forwards, transformNone 0.7s forwards;
      animation: fadeIn 0.7s forwards, transformNone 0.7s forwards;
      animation-delay: 0.5s;
    }

    &:nth-child(1) .form-questions__checkmark {
      -webkit-transform: translate(-190px, -70px);
      transform: translate(-190px, -70px);
    }
    &:nth-child(2) .form-questions__checkmark {
      -webkit-transform: translate(-190px, 0);
      transform: translate(-190px, 0);
    }
    &:nth-child(3) .form-questions__checkmark {
      -webkit-transform: translate(-190px, 70px);
      transform: translate(-190px, 70px);
    }
  }
}
