.portfolio-selector {
  padding-top: 50px;
  // @include for-bp(tablet-switch) {
  //   padding-top: 99px;
  // }

  &__top {
    text-align: center;
    max-width: 857px;
    margin: 0 auto 50px auto;
  }

  &__title {
    text-align: center;
    max-width: 717px;
    margin: 0 auto 20px;

    &--investor {
      display: inline-block;
      font-size: 14px;
      border-radius: 6px;
      padding: 9px 10px;
      background-color: get-color(white-6);
      font-weight: 300;
      font-family: $font-primary;
    }
  }

  &__description {
    margin: 0 auto;
    max-width: 600px;
    text-align: center;
    color: get-color(blue-2);
  }

  &__items {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    justify-content: center;
    flex-wrap: wrap;
    -webkit-column-gap: 30px;
    column-gap: 30px;
    row-gap: 30px;
    max-width: 900px;
    margin: 0 auto;
    @include for-bp(tablet-switch) {
      row-gap: 70px;
    }
  }

  &__item {
    position: relative;
    width: 250px;

    font-family: $font-secondary;
    border-radius: 6px;
    border: 2px solid get-color(blue-2);
    box-shadow: 2px -1px 9px 0px rgba(0, 0, 0, 0.2);
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    flex-direction: column;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
    -webkit-animation: cardGetIn 0.6s forwards;
    animation: cardGetIn 0.6s forwards;

    &.selected {
      outline: 2px solid get-color(black);
    }

    &-recommended {
      position: absolute;
      top: -25px;
      font-size: 20px;
      font-weight: 300;
      color: get-color(blue-2);
      width: 100%;
      text-align: center;
      letter-spacing: -0.38px;
      @include for-bp(tablet-switch) {
        top: -40px;
      }
    }

    &-top {
      color: get-color(blue-2);
      border-bottom: 1px solid get-color(black);
      padding: 20px 0 19px;
      text-transform: uppercase;
      font-size: 20px;
      text-align: center;
      font-weight: 500;

      margin: 0;
    }

    &-type {
      font-size: 14px;
      text-transform: none;
    }

    &-bottom {
      padding: 16px 20px 16px;
    }

    &-img {
      max-width: 164px;
      display: block;
      margin: 0 auto 10px;
      margin-bottom: 10px;
      aspect-ratio: 1;
    }

    &-buttons {
      display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
      justify-content: space-between;
      font-size: 15px;
      font-family: $font-secondary;
      letter-spacing: -0.285px;
    }

    &-button1 {
      color: get-color(white-1);
      border-radius: 4px;
      border: 1px solid #191a29;
      background: #1e1f38;
      box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.25);
      width: 86px;

      &.--current {
        opacity: 0.6;
        cursor: default;
      }
    }

    &-button2 {
      font-weight: 300;
      color: get-color(blue-2);
    }
  }
}
