.login-modal {
  &.--small-logo {
    .login-modal__logo {
      @include for-bp(mobile-md) {
        margin-bottom: 60px;
      }
    }
  }

  &__logo {
    color: get-color(black);
    width: 208px;
    height: 66px;
    margin: 0 auto 80px;
    max-width: 100%;
    @include for-bp(mobile-md) {
      margin-bottom: 80px;
    }
  }

  .form__items {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    -webkit-box-align: center; /* Safari 3.1-6.0, Chrome 4-21 */
    -webkit-align-items: center; /* Safari 6.1+, Chrome 21+ */
    -ms-flex-align: center; /* IE 10 */
    align-items: center;
    gap: 15px;
    flex-direction: column;
    margin-bottom: 35px;

    .form-group {
      width: 80%;
    }
  }

  &__form {
    color: get-color(blue-2);

    &-bottom {
      display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
      justify-content: space-between;
      -webkit-box-align: center; /* Safari 3.1-6.0, Chrome 4-21 */
    -webkit-align-items: center; /* Safari 6.1+, Chrome 21+ */
    -ms-flex-align: center; /* IE 10 */
    align-items: center;
      font-size: 1.4rem;
      flex-wrap: wrap;
      gap: 20px;
    }
  }

  &__login {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    margin: auto;
    margin-top: 50px;
  }

  &__arrow {
    width: 39px;
  }

  &__error {
    margin-bottom: 10px;
  }

  &__big-text {
    color: get-color(black);
    font-size: 2.8rem;
    margin-inline: auto;
    text-align: center;
    text-transform: uppercase;
  }
}
