.buttons-selector {
  &__title {
    text-align: center;

    @include for-bp(tablet-switch) {
      // margin-bottom: 125px;
      margin-bottom: 75px;
    }
  }

  &__options {
    display: -webkit-box;
    /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box;
    /* Firefox 2.0-19 */
    display: -ms-flexbox;
    /* IE 10 */
    display: -webkit-flex;
    /* Safari 6.1+, Chrome 21+ */
    display: flex;
    /* Estándar */
    gap: 30px;
    justify-content: center;
    flex-direction: column;
    // flex-wrap: wrap;
    max-width: 750px;
    margin: 0 auto;

    @include for-bp(tablet-lg) {
      gap: 30px;
      flex-direction: row;
    }
  }

  &__button {
    width: 220px;
    height: 60px;
    font-size: 20px;
    background-color: get-color(blue-6);
    border-color: get-color(blue-6);
    border-width: 2px;
    transition: all 0.5s;

    @include for-bp(tablet-switch) {
      width: 279px;
      height: 73px;
    }
  }

  &.animateCenter {
    .buttons-selector__button {
      opacity: 0;
      animation: transformNone 0.6s forwards, fadeIn 1s forwards;

      &:first-child {
        transform: translateX(50%);
      }

      &:last-child {
        transform: translateX(-50%);
      }
    }
  }

  &.animateTop {
    .buttons-selector__button {
      opacity: 0;
      animation: transformNone 0.6s forwards, fadeIn 1s forwards;

      &:nth-child(3),
      &:nth-child(4) {
        transform: translateY(-190%);
      }
    }
  }
}