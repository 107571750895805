.portfolio {
  // position: relative;
  padding: 52px 20px;
  padding-bottom: 0;

  @include for-bp(tablet-lg) {
    padding: 52px 40px;
    padding-bottom: 0;
  }

  span {
    font-family: $font-secondary;
  }

  &__graph {
    max-width: 377px;
  }

  &__wrapper {
    padding-top: 40px;
    padding-bottom: 100px;
    cursor: grab;

    @include for-bp(tablet-switch) {
      padding-top: 64px;
      padding-bottom: 120px;
    }
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    @include for-bp(tablet-lg) {
      position: absolute;
      top: -12.2rem;
      left: 20rem;
      height: 92px;
      max-width: 796px;
      width: calc(100vw - 42rem);
    }
  }

  &__title {
    // margin-bottom: 40px;
    // margin-top: 36px;
    margin-bottom: 0;
    letter-spacing: 1px;
    color: get-color(light-gray);
    font-weight: 500;
    // padding-right: 127px;
    text-align: center;
    font-size: 2.4rem;
    word-break: break-word;

    // height: 102px;
    @include for-bp(mobile-md) {
      font-size: 2.75rem;
    }

    @include for-bp(tablet-lg) {
      font-size: 3.25rem;
    }
  }

  &__content {
    display: grid;
    gap: 40px;
    min-height: 500px;

    @include for-bp(tablet-lg) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 50px;
    }
  }

  &__subtitle {
    text-transform: uppercase;
    margin-bottom: 23px;
    color: get-color(white-2);
    font-size: 20px;
    font-weight: 500;
    font-family: 'Brandon Grotesque';
    letter-spacing: .1rem;

    @include for-bp(tablet-lg) {
      margin-bottom: 43px;
    }
  }

  &__text {
    margin-bottom: 50px;
    font-family: $font-secondary;
    font-size: 20px;
    font-weight: 300;
  }

  &__list {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 0;

    &-item {
      border-bottom: 1px solid get-color(medium-gray);
      display: -webkit-box;
      /* Safari 3.1-6.0, Chrome 4-21 */
      display: -moz-box;
      /* Firefox 2.0-19 */
      display: -ms-flexbox;
      /* IE 10 */
      display: -webkit-flex;
      /* Safari 6.1+, Chrome 21+ */
      display: flex;
      /* Estándar */
      justify-content: space-between;
      padding-bottom: 4px;
      gap: 10px;

      &:last-child {
        @include for-bp(tablet-lg) {
          border-bottom: transparent;
        }
      }

      p {
        padding-top: 10px;
        font-size: 16px;

        @include for-bp(tablet-lg) {
          font-size: 20px;
        }
      }

      span {
        font-weight: 500;
        letter-spacing: 4.05px;
        font-size: 20px;
        color: get-color(dark-gray);

        @include for-bp(tablet-lg) {
          font-size: 28px;
        }
      }

      &.--blue span {
        color: get-color(blue);
      }
    }
  }

  &__left {
    display: -webkit-box;
    /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box;
    /* Firefox 2.0-19 */
    display: -ms-flexbox;
    /* IE 10 */
    display: -webkit-flex;
    /* Safari 6.1+, Chrome 21+ */
    display: flex;
    /* Estándar */
    flex-direction: column;
    -webkit-box-align: center;
    /* Safari 3.1-6.0, Chrome 4-21 */
    -webkit-align-items: center;
    /* Safari 6.1+, Chrome 21+ */
    -ms-flex-align: center;
    /* IE 10 */
    align-items: center;
    justify-content: end;
    padding: 0 20px;
  }

  &__right {
    display: -webkit-box;
    /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box;
    /* Firefox 2.0-19 */
    display: -ms-flexbox;
    /* IE 10 */
    display: -webkit-flex;
    /* Safari 6.1+, Chrome 21+ */
    display: flex;
    /* Estándar */
    flex-direction: column;
    gap: 0px;
    -webkit-box-align: center;
    /* Safari 3.1-6.0, Chrome 4-21 */
    -webkit-align-items: left;
    /* Safari 6.1+, Chrome 21+ */
    -ms-flex-align: left;
    /* IE 10 */
    align-items: left;
    -webkit-order: -1;
    order: -1;
    justify-content: center;

    @include for-bp(tablet-lg) {
      justify-content: end;
      -webkit-order: 1;
      order: 1;
    }
  }

  &__statistics {
    display: -webkit-box;
    /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box;
    /* Firefox 2.0-19 */
    display: -ms-flexbox;
    /* IE 10 */
    display: -webkit-flex;
    /* Safari 6.1+, Chrome 21+ */
    display: flex;
    /* Estándar */
    // flex-direction: column;
    gap: 29px;
    text-transform: uppercase;
    font-family: $font-secondary;
    text-align: center;
    color: get-color(dark-gray);
    width: 100%;
    margin-top: 35px;
    margin-bottom: 0;

    &-item {
      display: flex;
      flex-direction: column;
      gap: .2rem;
      flex: 1;
      padding: 10px 13px;
      border-radius: 10px;
      border: 1px solid get-color(dark-gray);

      &.--blue {
        color: get-color(blue);
      }

      >div {
        font-size: 17px;
        font-weight: 400;
        line-height: 1;
        /* 15.825px */
        letter-spacing: -0.285px;
      }

      >span {
        font-size: 32px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.608px;
      }
    }
  }

  &__metrics {
    padding-top: 13px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 0px !important;

    &-list {
      display: -webkit-box;
      /* Safari 3.1-6.0, Chrome 4-21 */
      display: -moz-box;
      /* Firefox 2.0-19 */
      display: -ms-flexbox;
      /* IE 10 */
      display: -webkit-flex;
      /* Safari 6.1+, Chrome 21+ */
      display: flex;
      /* Estándar */
      flex-direction: column;
      -webkit-box-align: flex-start;
      /* Safari 3.1-6.0, Chrome 4-21 */
      -webkit-align-items: flex-start;
      /* Safari 6.1+, Chrome 21+ */
      -ms-flex-align: flex-start;
      /* IE 10 */
      align-items: flex-start;
      margin: 0px;

      @include for-bp(tablet-lg) {
        -webkit-box-align: left;
        /* Safari 3.1-6.0, Chrome 4-21 */
        -webkit-align-items: left;
        /* Safari 6.1+, Chrome 21+ */
        -ms-flex-align: left;
        /* IE 10 */
        align-items: left;
        flex-direction: column;
      }

      >li {
        display: -webkit-box;
        /* Safari 3.1-6.0, Chrome 4-21 */
        display: -moz-box;
        /* Firefox 2.0-19 */
        display: -ms-flexbox;
        /* IE 10 */
        display: -webkit-flex;
        /* Safari 6.1+, Chrome 21+ */
        display: flex;
        /* Estándar */
        gap: 30px;
        -webkit-box-align: center;
        /* Safari 3.1-6.0, Chrome 4-21 */
        -webkit-align-items: center;
        /* Safari 6.1+, Chrome 21+ */
        -ms-flex-align: center;
        /* IE 10 */
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid get-color(medium-gray);
        padding: 3px 0;

        @include for-bp(tablet-lg) {
          // border-right: 1px solid get-color(gray-1);
          // padding: 0 30px;
        }

        /* &:first-child {
          @include for-bp(tablet-lg) {
            justify-content: space-between;
            padding-left: 0;
          }
        } */

        &:last-child {
          @include for-bp(tablet-lg) {
            border-bottom: transparent;
          }
        }

        >span {
          font-size: 20px;
          font-weight: 700;
          color: get-color(dark-gray);

          // letter-spacing: 3.375px;
          @include for-bp(tablet-lg) {
            font-size: 20px;
          }
        }

        >div {
          font-size: 16px;

          @include for-bp(tablet-lg) {
            font-size: 20px;
          }
        }
      }
    }
  }

  &__annual_returns {
    display: flex;
    flex-direction: column;
    gap: 34px;
  }

  &__description {
    padding-top: 11px;
    font-size: 13px;
    color: get-color(dark-gray);
  }

  &__print {
    position: absolute;
    right: 40px;
    bottom: -15px;
    display: -webkit-box;
    /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box;
    /* Firefox 2.0-19 */
    display: -ms-flexbox;
    /* IE 10 */
    display: -webkit-flex;
    /* Safari 6.1+, Chrome 21+ */
    display: flex;
    /* Estándar */
    flex-direction: column;
    -webkit-box-align: center;
    /* Safari 3.1-6.0, Chrome 4-21 */
    -webkit-align-items: center;
    /* Safari 6.1+, Chrome 21+ */
    -ms-flex-align: center;
    /* IE 10 */
    align-items: center;
    color: get-color(gray-1);

    @include for-bp(desktop-md) {
      right: 90px;
    }

    &-logo {
      width: 48px;
      height: 48px;
    }
  }

  &--modal {
    color: get-color(black);

    >.h-wrapper {
      display: flex;
      flex-direction: column;
      gap: 42px;
      padding-top: 0;
      padding-bottom: 50px;
    }

    .portfolio {
      &__title {
        color: get-color(black);
        text-align: center;
        // margin-bottom: 26px;
      }

      &__subtitle {
        color: get-color(black);
        margin-bottom: 0px;
      }

      &__right {
        -webkit-order: -1;
        order: -1;
      }

      &__metrics {
        margin-top: 62px;
      }
    }
  }
}

.portfolio-minimal {
  position: relative;
  padding: 20px 0;

  span {
    font-family: $font-secondary;
  }

  &__graph {
    max-width: 377px;
  }

  &__wrapper {
    padding-top: 40px;
    padding-bottom: 100px;
    cursor: grab;

    @include for-bp(tablet-switch) {
      padding-top: 64px;
      padding-bottom: 120px;
    }
  }

  &__title {
    // margin-bottom: 40px;
    // margin-top: 36px;
    margin-bottom: 0;
    color: get-color(light-gray);
    font-weight: 500;
    // padding-right: 127px;
    text-align: center;
    font-size: 2.4rem;
    word-break: break-word;
    padding: 0 30px;

    // height: 102px;
    @include for-bp(mobile-md) {
      font-size: 3rem;
    }

    @include for-bp(tablet-lg) {
      font-size: 3.8rem;
      // margin-bottom: 72px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 34px;
    min-height: 500px;
    // padding-bottom: 75px;
    padding-top: 34px;
    align-items: center;
    justify-content: center;

    @include for-bp(tablet-lg) {
      gap: 100px;
      // padding-top: 50px;
      flex-direction: row;
    }
  }

  /* &__content {
    display: grid;
    gap: 40px;
    min-height: 500px;
    // padding-bottom: 75px;

    @include for-bp(tablet-lg) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 100px;
    }
  } */

  &__subtitle {
    text-transform: uppercase;
    margin-bottom: 30px;
    color: get-color(white-2);
    font-size: 20px;
    font-weight: 500;
    font-family: 'Brandon Grotesque';
    letter-spacing: .1rem;
    text-align: left;
    width: 100%;

    @include for-bp(tablet-lg) {
      margin-bottom: 30px;
    }
  }

  &__text {
    margin-bottom: 50px;
    font-family: $font-secondary;
    font-size: 20px;
    font-weight: 300;
  }

  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;

    &-item {
      // border-bottom: 1px solid get-color(medium-gray);
      display: -webkit-box;
      /* Safari 3.1-6.0, Chrome 4-21 */
      display: -moz-box;
      /* Firefox 2.0-19 */
      display: -ms-flexbox;
      /* IE 10 */
      display: -webkit-flex;
      /* Safari 6.1+, Chrome 21+ */
      display: flex;
      /* Estándar */
      justify-content: space-between;
      align-items: center;
      padding-bottom: 4px;
      gap: 50px;
      max-width: calc(100vw - 52px);

      &:last-child {
        @include for-bp(tablet-lg) {
          border-bottom: transparent;
        }
      }

      p {
        // padding-top: 10px;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include for-bp(tablet-lg) {
          font-size: 20px;
        }
      }

      span {
        font-weight: 500;
        letter-spacing: 4.05px;
        font-size: 20px;
        color: get-color(dark-gray);

        @include for-bp(tablet-lg) {
          font-size: 28px;
        }
      }

      &.--blue span {
        color: get-color(blue);
        font-weight: 800;
      }
    }
  }

  &__left {
    display: -webkit-box;
    /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box;
    /* Firefox 2.0-19 */
    display: -ms-flexbox;
    /* IE 10 */
    display: -webkit-flex;
    /* Safari 6.1+, Chrome 21+ */
    display: flex;
    /* Estándar */
    flex-direction: column;
    -webkit-box-align: center;
    /* Safari 3.1-6.0, Chrome 4-21 */
    -webkit-align-items: center;
    /* Safari 6.1+, Chrome 21+ */
    -ms-flex-align: center;
    /* IE 10 */
    align-items: center;
    justify-content: start;
    // padding: 20px;
    // padding-top: 30px;
  }

  &__right {
    display: -webkit-box;
    /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box;
    /* Firefox 2.0-19 */
    display: -ms-flexbox;
    /* IE 10 */
    display: -webkit-flex;
    /* Safari 6.1+, Chrome 21+ */
    display: flex;
    /* Estándar */
    // flex-direction: column;
    gap: 30px;
    -webkit-box-align: center;
    /* Safari 3.1-6.0, Chrome 4-21 */
    -webkit-align-items: left;
    /* Safari 6.1+, Chrome 21+ */
    -ms-flex-align: left;
    /* IE 10 */
    align-items: center;
    -webkit-order: -1;
    order: -1;
    justify-content: center;
    width: fit-content;
    // margin: auto;

    @include for-bp(tablet-lg) {
      justify-content: center;
      flex-direction: column;
      -webkit-order: 1;
      order: 1;
      // margin-left: auto;
      // margin-right: 0;
    }
  }

  &__statistics {
    display: -webkit-box;
    /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box;
    /* Firefox 2.0-19 */
    display: -ms-flexbox;
    /* IE 10 */
    display: -webkit-flex;
    /* Safari 6.1+, Chrome 21+ */
    display: flex;
    /* Estándar */
    flex-direction: column;
    margin-bottom: 0;
    gap: 16px;
    text-transform: uppercase;
    font-family: $font-secondary;
    text-align: center;
    color: get-color(dark-gray);
    width: fit-content;

    @include for-bp(tablet-lg) {
      width: 100%;
      margin-top: 35px;
      flex-direction: row;
      gap: 29px;
    }

    &-item {
      display: flex;
      flex-direction: column;
      gap: .2rem;
      flex: 1;
      padding: 8px 6px;
      border-radius: 10px;
      border: 1px solid get-color(dark-gray);
      max-height: fit-content;
      width: 90px;

      @include for-bp(tablet-lg) {
        padding: 10px 13px;
        width: 150px;
      }

      &.--blue {
        color: get-color(blue);
      }

      &.--blue span {
        font-weight: 800;
      }

      >div {
        font-size: 15px;
        font-weight: 400;
        line-height: 1;
        /* 15.825px */
        letter-spacing: -0.285px;

        @include for-bp(tablet-lg) {
          font-size: 17px;
        }
      }

      >span {
        font-size: 30px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.608px;

        @include for-bp(tablet-lg) {
          font-size: 32px;
        }
      }
    }
  }

  &__metrics {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 0px !important;

    &-list {
      display: -webkit-box;
      /* Safari 3.1-6.0, Chrome 4-21 */
      display: -moz-box;
      /* Firefox 2.0-19 */
      display: -ms-flexbox;
      /* IE 10 */
      display: -webkit-flex;
      /* Safari 6.1+, Chrome 21+ */
      display: flex;
      /* Estándar */
      flex-direction: column;
      -webkit-box-align: flex-start;
      /* Safari 3.1-6.0, Chrome 4-21 */
      -webkit-align-items: flex-start;
      /* Safari 6.1+, Chrome 21+ */
      -ms-flex-align: flex-start;
      /* IE 10 */
      align-items: flex-start;
      margin: 0px;

      @include for-bp(tablet-lg) {
        -webkit-box-align: left;
        /* Safari 3.1-6.0, Chrome 4-21 */
        -webkit-align-items: left;
        /* Safari 6.1+, Chrome 21+ */
        -ms-flex-align: left;
        /* IE 10 */
        align-items: left;
        flex-direction: column;
      }

      >li {
        display: -webkit-box;
        /* Safari 3.1-6.0, Chrome 4-21 */
        display: -moz-box;
        /* Firefox 2.0-19 */
        display: -ms-flexbox;
        /* IE 10 */
        display: -webkit-flex;
        /* Safari 6.1+, Chrome 21+ */
        display: flex;
        /* Estándar */
        gap: 30px;
        -webkit-box-align: center;
        /* Safari 3.1-6.0, Chrome 4-21 */
        -webkit-align-items: center;
        /* Safari 6.1+, Chrome 21+ */
        -ms-flex-align: center;
        /* IE 10 */
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid get-color(medium-gray);
        padding: 3px 0;

        @include for-bp(tablet-lg) {
          // border-right: 1px solid get-color(gray-1);
          // padding: 0 30px;
        }

        /* &:first-child {
          @include for-bp(tablet-lg) {
            justify-content: space-between;
            padding-left: 0;
          }
        } */

        &:last-child {
          @include for-bp(tablet-lg) {
            border-bottom: transparent;
          }
        }

        >span {
          font-size: 20px;
          font-weight: 700;
          color: get-color(dark-gray);

          // letter-spacing: 3.375px;
          @include for-bp(tablet-lg) {
            font-size: 20px;
          }
        }

        >div {
          font-size: 16px;

          @include for-bp(tablet-lg) {
            font-size: 20px;
          }
        }
      }
    }
  }

  &__annual_returns {
    display: flex;
    flex-direction: column;
    gap: 34px;
  }

  &__description {
    font-size: 13px;
    color: get-color(dark-gray);
  }

  &__print {
    position: absolute;
    right: 40px;
    bottom: -15px;
    display: -webkit-box;
    /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box;
    /* Firefox 2.0-19 */
    display: -ms-flexbox;
    /* IE 10 */
    display: -webkit-flex;
    /* Safari 6.1+, Chrome 21+ */
    display: flex;
    /* Estándar */
    flex-direction: column;
    -webkit-box-align: center;
    /* Safari 3.1-6.0, Chrome 4-21 */
    -webkit-align-items: center;
    /* Safari 6.1+, Chrome 21+ */
    -ms-flex-align: center;
    /* IE 10 */
    align-items: center;
    color: get-color(gray-1);

    @include for-bp(desktop-md) {
      right: 90px;
    }

    &-logo {
      width: 48px;
      height: 48px;
    }
  }

  &--modal {
    color: get-color(black);

    >.h-wrapper {
      display: flex;
      flex-direction: column;
      gap: 70px;
      padding-top: 0;
      padding-bottom: 50px;
    }

    .portfolio {
      &__title {
        color: get-color(black);
        text-align: center;
        // margin-bottom: 26px;
      }

      &__subtitle {
        color: get-color(black);
        margin-bottom: 0px;
      }

      &__right {
        -webkit-order: -1;
        order: -1;
      }

      &__metrics {
        margin-top: 62px;
      }
    }
  }
}