.fadeInTop {
  opacity: 0;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
  -webkit-animation: fadeIn 0.7s ease-in forwards, transformNone 0.5s ease-in forwards;
  animation: fadeIn 0.7s ease-in forwards, transformNone 0.5s ease-in forwards;

  &--small {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn 0.6s ease-in forwards;
  animation: fadeIn 0.6s ease-in forwards;
}

.fromRight {
  opacity: 0;
  -webkit-transform: translatex(100vh);
  transform: translatex(100vh);
  -webkit-animation: fadeIn 0.8s forwards, fromRight 0.6s forwards;
  animation: fadeIn 0.8s forwards, fromRight 0.6s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes fromRight {
  to {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes transformNone {
  to {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
