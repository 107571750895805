/* // ------------------------------------------------ */
/* Folders*/
/* // ------------------------------------------------ */

$assetsFolder: '';

$header-height: 114px;
$header-height-desktop: 114px;
/*
// ------------------------------------------------
// Colors
// ------------------------------------------------ */

$colors: (
  black: #000000,
  white: #fff,
  blue: #102DA9,
  dark-gray: #A8A8A8,
  medium-gray: #DADADA,
  light-gray: #f0efef,
  error: #FF6363,
  white-1: #cac6c6,
  white-2: #f0efef,
  white-3: #efefef,
  white-4: #d9d9de,
  white-6: #d9d9d9,
  gray-1: #6f6f6f,
  gray-2: #bdbdbe,
  gray-3: #929292,
  gray-4: #3a3939,
  gray-5: #a1a1a5,
  gray-6: #292932,
  gray-7: #a7a7a7,
  gray-8: #d8d8db,
  gray-9: #9a9a9a,
  gray-10: #a8a8a8,
  gray-11: #5e5d5d,
  blue-1: #323c94,
  blue-2: #222228,
  blue-3: #4d59c8,
  blue-4: #2d2e39,
  blue-5: #27272f,
  blue-6: #1e1e22,
  purple: #2b3275,
  red: #951111,
  green: green,
);

/*Gradients*/

// ------------------------------------------------
// RESPONSIVE DESIGN BREAKPOINTS
// Separate with commas
// to be used in conjunction with '@mixin for-breakpoint(n)' found in sass/custom/mixins/_responsive.scss
// ------------------------------------------------

$media-query-breakpoints: (
  mobile-md: 480px,
  tablet-switch: 720px,
  tablet-lg: 992px,
  desktop-md: 1240px,
  desktop-lg: 1400px,
  desktop-xlg: 1600px,
);

// ------------------------------------------------
// Z-indexes
// ------------------------------------------------

$z-index: (
  base: -1,
  under-base: 1,
  low: 10,
  medium: 50,
  top: 1000,
);

// ------------------------------------------------
// Measures
// ------------------------------------------------

$mobile-bleed: 22px;
$tablet-bleed: 40px;
$desktop-bleed: 70px;

$desktop-max-width: 1440px;

// ------------------------------------------------
// Time
// ------------------------------------------------

$transTime: 300ms;

// ------------------------------------------------
// Fonts
// ------------------------------------------------

$font-primary: 'Bellota Text', cursive;
$font-secondary: 'Brandon Grotesque', sans-serif;
$font-signature: 'Allura', cursive;
