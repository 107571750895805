.email-verification-instructions {
  text-align: center;
  color: get-color(blue-2);
  display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
  display: -moz-box; /* Firefox 2.0-19 */
  display: -ms-flexbox; /* IE 10 */
  display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
  display: flex; /* Estándar */
  justify-content: space-between;
  flex-direction: column;
  min-height: calc(100vh - 2 * #{$header-height});

  &__content {
    flex-grow: 1;
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    flex-direction: column;
    justify-content: center;
    font-size: 3rem;
    -webkit-box-align: center; /* Safari 3.1-6.0, Chrome 4-21 */
    -webkit-align-items: center; /* Safari 6.1+, Chrome 21+ */
    -ms-flex-align: center; /* IE 10 */
    align-items: center;
    gap: 30px;
  }

  &__error {
    color: get-color(red);
    padding: 5px 10px;
    border: 1px solid get-color(red);
    font-size: 2rem;
    font-weight: 600;
  }

  &__button {
    font-size: 2rem;
    height: 45px;
  }
}
