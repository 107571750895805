* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%; // http://hugogiraudel.com/2013/03/18/ultimate-rem-mixin/ */
  margin: 0;
  overflow-x: hidden;
  font-display: swap;
  font-family: $font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 1.6rem;
  line-height: 1.4;
  color: get-color(medium-gray);

  &.m--open {
    height: 100vh;
  }
}

input,
input::placeholder {
  font-family: inherit;
}

*::placeholder {
  color: inherit;
}

img,
object,
iframe,
embed {
  max-width: 100%;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

button {
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  padding: 0;
}

li {
  list-style-type: none;
}

input[type='number'] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
