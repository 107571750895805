.form-crs {
  color: #000000;
  padding: 40px 0;

  .title {
    text-align: center;
    text-transform: uppercase;
  }

  h4 {
    line-height: 1.25;
    margin-block-start: 0;
    margin-block-end: 0;
    font-weight: 100;
  }

  p {
    padding-bottom: 2rem;
  }
}
