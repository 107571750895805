.h_small-loader-container {
  display: grid;
  place-content: center;
}

.h_small-loader {
  width: 39px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: get-color(gray-3);
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  -webkit-animation: l3 1s infinite linear;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
