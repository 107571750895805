.contact-form {
  min-height: calc(100vh - 2 * #{$header-height});
  display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
  display: -moz-box; /* Firefox 2.0-19 */
  display: -ms-flexbox; /* IE 10 */
  display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
  display: flex; /* Estándar */
  flex-direction: column;
  -webkit-box-align: center; /* Safari 3.1-6.0, Chrome 4-21 */
  -webkit-align-items: center; /* Safari 6.1+, Chrome 21+ */
  -ms-flex-align: center; /* IE 10 */
  align-items: center;
  gap: 20px;
  // justify-content: center;
  // padding-block: 50px;
  padding: 40px 0;

  &__form {
    max-width: 100%;
    width: 500px;

    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    flex-direction: column;
  }

  &__message {
    height: unset;
    padding-top: 10px;
    resize: vertical;
    max-height: 200px;
  }

  &__submit {
    margin-inline: auto;
    -webkit-transition: all 0.15s;
    transition: all 0.15s;
  }

  .h_small-loader {
    margin-inline: auto;
  }

  &__title {
    text-transform: uppercase;
    margin-bottom: 0 !important;
    color: get-color(black);
    font-size: 20px;
    letter-spacing: -0.456px;

    @include for-bp(tablet-switch) {
      font-size: 24px;
      margin-bottom: 0 !important;
    }
  }

  &__paragraph {
    max-width: 500px;
    color: rgb(120, 120, 120);
  }

  .contact__input {
    width: 100%;
  }

  .pt-8 {
    padding-top: 8px;
  }
}
