.autocomplete {
  &__container {
    position: relative;
  }

  padding-top: 10px;

  ::placeholder {
    color: get-color(gray-7);
    font-family: $font-secondary;
  }

  &__search {
    color: get-color(blue-2);
    position: absolute;
    max-height: 150px;
    overflow-y: scroll;
    background-color: get-color(light-gray);
    z-index: 1;
    margin-top: 0;
    padding-top: 10px;
    border-radius: 6px;
    width: 100%;
    display: none;

    &.--show {
      display: block;
    }

    &-item {
      cursor: pointer;
      padding: 11px 10px;
      text-align: center;
      text-transform: uppercase;
      border-left: 3px solid transparent;
      border-top: 1px solid get-color(white-6);
      border-bottom: 1px solid get-color(white-6);

      &:hover {
        background-color: get-color(light-gray);
        border-left-color: get-color(black);
      }
    }
  }
}
