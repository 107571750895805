.forgot-complete {
  color: get-color(black);
  text-align: center;
  display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
  display: -moz-box; /* Firefox 2.0-19 */
  display: -ms-flexbox; /* IE 10 */
  display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
  display: flex; /* Estándar */
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  &__text {
  }
}
