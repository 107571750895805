.header {
  background-color: get-color(black);
  height: $header-height;
  border-bottom: 1px solid get-color(gray-1);
  display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
  justify-content: center;
  -webkit-box-align: center; /* Safari 3.1-6.0, Chrome 4-21 */
    -webkit-align-items: center; /* Safari 6.1+, Chrome 21+ */
    -ms-flex-align: center; /* IE 10 */
    align-items: center;
  cursor: default;

  @include for-bp(tablet-lg) {
    height: $header-height-desktop;
  }

  >.h-wrapper {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    justify-content: space-between;
    align-items: center;
    height: 100%;
    gap: 20px;
    padding: 10px 22px;

    @include for-bp(mobile-md) {
      padding: 10px 70px;
    }

    >a {
      line-height: 1;
      display: inline-flex;
      align-items: center;
      max-width: 165px;
      @include for-bp(mobile-md) {
        max-width: 200px;
      }
    }
  }

  &__logo {
    height: 46px;
    color: get-color(white-2);
    max-width: 100%;

    @include for-bp(tablet-lg) {
      width: 182px;
      height: 65px;
    }
  }

  &__buttons {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    gap: 20px;

    @include for-bp(tablet-switch) {
      gap: 35px;
    }
  }

  &__button {
    width: min-content;
    text-transform: uppercase;
    padding: 7px 0;
    position: relative;
    -webkit-transition: color 0.4s;
    transition: color 0.4s;
    text-align: center;
    font-size: 1.4rem;
    line-height: normal;

    @include for-bp(tablet-switch) {
      font-size: 1.6rem;
      padding: 7px 0;
    }

    &:not(div):hover {
      color: get-color(white);
    }

    &:first-child {
      // border-right: 1px solid get-color(white-1);
      // width: 87px;
      padding-left: 0;

      @include for-bp(tablet-switch) {
        width: auto;
      }
    }

    &.--no-border:first-child {
      border-right: none;
    }

    &:last-child {
      padding-right: 0;
      // width: 70px;

      @include for-bp(tablet-switch) {
        width: auto;
      }
    }
  }

  &--without-border &__button {
    border: none;
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0px;
  width: 210px;
  background-color: black;
  z-index: 1;
  padding-top: 1rem;

  .dropdown__button {
    padding: 1rem;
    display: block;
    text-transform: uppercase;
    position: relative;
    -webkit-transition: border 0.4s;
    transition: border 0.4s;
    text-align: right;
    width: 100%;
    border: 1px solid black;
  }

  .dropdown__button:hover {
    border: 1px solid white;
  }
}