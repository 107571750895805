.required-message {
  font-size: 1.4rem;
  font-weight: 500;
  color: get-color(red);
  text-transform: uppercase;
  font-family: $font-secondary;
  position: relative;
  opacity: 0;
  -webkit-animation: fadeIn 0.7s forwards;
  animation: fadeIn 0.7s forwards;

  &.--buttons-selector &__container {
    position: absolute;
    top: -30px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    justify-content: center;
    @include for-bp(tablet-switch) {
      top: -40px;
    }
  }

  &.--input-form &__container {
    position: absolute;
    top: -30px;
  }

  &__container {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    gap: 5px;
  }

  &__icon {
    width: 17px;
    height: 18px;
  }
}
