.trust2 {
  &__post-code {
    @include for-bp(tablet-switch) {
      width: 70%;
    }
  }

  &__bottom {
    margin-top: 41px;
  }
}
