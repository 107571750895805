.h-form-button {
  width: 130px;
  max-width: 100%;
  background-color: get-color(blue-6);
  color: get-color(white-1);
  border-radius: 10px;
  text-transform: uppercase;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 14px;
  border: 1px solid get-color(blue-6);
  -webkit-transition: all 0.15s ease-in-out, opacity 0.15s;
  transition: all 0.15s ease-in-out, opacity 0.15s;
  font-weight: 700;

  &:not(:disabled) {
    &:hover {
      background-color: get-color(light-gray);
      border-color: get-color(medium-gray);
      color: #000;
    }
  }

  @include for-bp(mobile-md) {
    width: 190px;
  }

  &:disabled {
    cursor: not-allowed;
    border: 1px solid get-color(medium-gray);
    background-color: get-color(light-gray);
    color: get-color(dark-gray);
    font-weight: bolder;
  }

  &.--hide {
    opacity: 0;
    width: 0;
  }

  &.--hide span {
    opacity: 0;
  }

  &.--gray {
    color: get-color(blue-2);
    background-color: get-color(gray-8);
    border-color: get-color(gray-7);
  }

  &.--active {
    border: 2px solid get-color(dark-gray) !important;
    color: get-color(black);
    background-color: get-color(light-gray);
  }

  &.--small {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

.h-form-group-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  -webkit-column-gap: 20px;
  column-gap: 20px;
  row-gap: 20px;
  align-items: end;
  @include for-bp(tablet-switch) {
    row-gap: 41px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  // @for $i from 1 through 5 {
  //   .row-mobile-#{$i} {
  //     grid-row: $i;
  //   }
  // }

  // @for $i from 1 through 5 {
  //   .row-desktop-#{$i} {
  //     @include for-bp(tablet-switch) {
  //       grid-row: $i;
  //     }
  //   }
  // }

  // @for $i from 1 through 5 {
  //   .span-mobile-#{$i} {
  //     grid-column: span $i;
  //   }
  // }

  // @for $i from 1 through 5 {
  //   .span-desktop-#{$i} {
  //     @include for-bp(tablet-switch) {
  //       grid-column: span $i;
  //     }
  //   }
  // }

  .span-2-xs {
    grid-column: span 2;
    @include for-bp(mobile-md) {
      grid-column: unset;
    }
  }

  .spacer-tablet {
    display: none;
    @include for-bp(tablet-switch) {
      display: block;
    }
  }

  &.--joint {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    @include for-bp(tablet-switch) {
      grid-template-columns: 84px repeat(3, minmax(0, 1fr));
    }
  }

  &.--cols-2 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    @include for-bp(tablet-switch) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &.--cols-4 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    @include for-bp(tablet-switch) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}

.h-form-title {
  text-transform: uppercase;
  margin-bottom: 35px;
  color: get-color(black);
  font-size: 20px;
  letter-spacing: -0.456px;
  @include for-bp(tablet-switch) {
    font-size: 24px;
    margin-bottom: 55px;
  }
}

.h-form-label {
  text-transform: uppercase;
  font-size: 24px;
  font-family: $font-secondary;
  color: black;
  margin-bottom: 45px;
}

.h-form-text {
  text-transform: uppercase;
  font-size: 15px;
  font-family: $font-secondary;
  color: get-color(gray-11);
}

.h-form-padding {
  padding: 60px 0 0;
}
