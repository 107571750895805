.m {
  &--open {
    overflow: hidden;
  }

  &__overlay {
    background: rgba(get-color(blue-2), 0.6);
    inset: 0;
    position: fixed;
    z-index: 100;
    backdrop-filter: blur(4px) saturate(180%);
    -webkit-backdrop-filter: blur(4px) saturate(180%);
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    -webkit-box-align: center; /* Safari 3.1-6.0, Chrome 4-21 */
    -webkit-align-items: center; /* Safari 6.1+, Chrome 21+ */
    -ms-flex-align: center; /* IE 10 */
    align-items: center;
    justify-content: center;
  }

  &__body {
    overflow-y: scroll;
    height: -webkit-fill-available;
    position: relative;
  }

  &__close {
    width: 23px;
    height: 23px;
    position: absolute;
    right: 29px;
    top: 21px;
    color: get-color(gray-1);

    /* @include for-bp(tablet-switch) {
      width: 29px;
      height: 29px;
    } */
  }

  &__container {
    position: relative;
    overflow-y: auto;
    // inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    // overflow: auto;
    outline: none;
    padding: 65px 30px 45px;
    border-radius: 10px;
    width: 665px;
    max-width: calc(100% - 20px);
    max-height: 100%;
    height: 580px;
    // left: 50%;
    // top: 50%;
    // transform: translateX(-50%) translateY(-50%);
    // box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.25);
    @include for-bp(mobile-md) {
      max-width: calc(100% - 50px);
    }
    @include for-bp(tablet-switch) {
      padding: 65px;
      max-width: calc(100% - 100px);
    }

    &--big {
      position: absolute;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      max-height: calc(100vh - 100px);
      height: auto;
      width: 1116px;
      padding: 15px;
      bottom: 50px;
      top: 50px;
      left: 50%;

      @include for-bp(tablet-switch) {
        bottom: 50px;
        top: 50px;
        padding: 38px;
      }

      .login-modal__logo {
        margin-left: 0;
        margin-bottom: 0;
        max-width: 275px;
      }
    }

    &--simple {
      color: get-color(red);
      font-size: 24px;
      height: fit-content;
      text-align: center;

      .login-modal__logo {
        display: none;
      }
    }
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}