.account-steps {
  padding: 50px 0;
  padding-bottom: 20px;
  font-family: $font-secondary;
  text-transform: uppercase;
  background-color: get-color(black);
  color: get-color(white-2);
  @include for-bp(tablet-switch) {
    padding: 90px 0;
    padding-bottom: 60px;
  }

  &__title {
    letter-spacing: 1.064px;
    text-align: center;
    font-weight: 600;
  }

  &__steps {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    justify-content: space-between;
    flex-flow: column wrap;
    -webkit-box-align: center; /* Safari 3.1-6.0, Chrome 4-21 */
    -webkit-align-items: center; /* Safari 6.1+, Chrome 21+ */
    -ms-flex-align: center; /* IE 10 */
    align-items: center;
    @include for-bp(tablet-switch) {
      flex-flow: column wrap;
      -webkit-box-align: center; /* Safari 3.1-6.0, Chrome 4-21 */
    -webkit-align-items: center; /* Safari 6.1+, Chrome 21+ */
    -ms-flex-align: center; /* IE 10 */
    align-items: center;
    }
    @include for-bp(desktop-md) {
      flex-flow: row nowrap;
    }
  }

  &__step {
    position: relative;
    overflow: hidden;
    max-width: 320px;
    width: 100%;

    &:nth-child(1) {
      .account-steps__hidden-text {
        @include for-bp(desktop-md) {
          max-width: 248px;
          padding: 47px 5px 0 30px;
        }
      }
    }
  }

  // For the animation
  //&__step:hover &__text-container {
  //  left: 48px;
  //}

  &__text-container {
    border: 1px solid get-color(white-2);
    border-left: 0;
    position: absolute;
    top: 33px;
    height: 127px;
    padding: 0;
    text-transform: uppercase;
    font-weight: 600;
    z-index: 50;
    font-size: 16px;
    -webkit-transition: all 1s;
    transition: all 1s;
    left: 48px;
    //@include for-bp(tablet-switch) {
    //  left: -100%;
    //}
  }

  &__hidden-text {
    max-width: 248px;
    padding: 47px 5px 0 58px;
  }

  &__number-container {
  }

  &__after-container {
    position: relative;
    width: fit-content;

    &::after {
      content: '';
      position: absolute;
      z-index: 40;
      height: 90%;
      width: 90%;
      background-color: get-color(black);
      left: -11px;
      top: 0;
    }
  }

  &__label {
    position: absolute;
    font-size: 24px;
    letter-spacing: 0.672px;
    top: 40px;
    left: 58px;
    font-weight: 600;
  }

  &__step {
    &:nth-of-type(1) .account-steps__label {
      left: 106px;
      @include for-bp(desktop-md) {
        left: 81px;
      }
    }

    &:nth-of-type(2) .account-steps__label {
      left: 102px;
    }

    &:nth-of-type(3) .account-steps__label {
      left: 102px;
    }
  }

  &__number {
    width: fit-content;
    font-size: 200px;
    line-height: 1;
    position: relative;
    z-index: 100;
    &:nth-of-type(1) {
      display: grid;
      place-content: center;
      width: 103px;
    }
  }

  &__number_1 {
    position: relative;
    left: -4px;
  }
}
