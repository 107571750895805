@mixin btn-border-drawing(
  $color: #ccc,
  $hover: black,
  $color-hover: #ccc,
  $width: 2px,
  $vertical: top,
  $horizontal: left,
  $duration: 0.25s
) {
  box-shadow: inset 0 0 0 $width $color;
  color: $color;
  -webkit-transition: color $duration calc($duration/3);
  transition: color $duration calc($duration/3);
  position: relative;

  &::before,
  &::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0;
    height: 0;

    #{$vertical}: 0;
    #{$horizontal}: 0;
  }

  &::before {
    $h-side: if($horizontal == 'left', 'right', 'left');

    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }

  &::after {
    $v-side: if($vertical == 'top', 'bottom', 'top');

    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;
  }

  &:hover {
    //color: $hover;
    color: $color-hover;

    &::before,
    &::after {
      border-color: $color-hover;
      -webkit-transition: border-color 0s, width $duration, height $duration;
      transition: border-color 0s, width $duration, height $duration;
      width: 100%;
      height: 100%;
    }

    &::before {
      transition-delay: 0s, 0s, $duration;
    }

    &::after {
      transition-delay: 0s, $duration, 0s;
    }
  }
}

.h-button {
  background: #000;
  color: #fff;

  padding: 16px 51px;
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 1.7rem;
  font-weight: 400;
  height: fit-content;
  @include for-bp(tablet-lg) {
    font-size: 2.1rem;
  }

  &:disabled {
    cursor: not-allowed;
    border: none;
    background-color: get-color(gray-8);
    color: get-color(gray-3);
    font-weight: bolder;
  }

}
