.dropdown {
  position: relative;

  &__top {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    justify-content: space-between;
    color: get-color(blue-2);
    padding: 8px 5px;
    cursor: pointer;
    -webkit-box-align: center; /* Safari 3.1-6.0, Chrome 4-21 */
    -webkit-align-items: center; /* Safari 6.1+, Chrome 21+ */
    -ms-flex-align: center; /* IE 10 */
    align-items: center;
  }

  &__header {
    text-transform: uppercase;
  }

  &__arrow {
    width: 21px;
    height: 21px;
    -webkit-transition: transform 0.2s;
    transition: transform 0.2s;

    &.--arrow-top {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  &__container {
    position: absolute;
    width: 100%;
    background-color: get-color(white-2);
    border-right: 6px;
    text-align: center;
    margin-top: 0;
    display: none;
    z-index: 100;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;

    &.--active {
      display: block;
    }
  }

  &__option {
    font-size: 14px;
    font-family: $font-secondary;
    cursor: pointer;
    color: get-color(black);
    padding: 11px 0;
    -webkit-transition: all 0.5s;
    border-left: 3px solid transparent;
    border-top: 1px solid get-color(white-6);
    border-bottom: 1px solid get-color(white-6);
    transition: all 0.5s;

    /* &:last-child {
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:first-child {
      border-radius: 6px 6px 0 0;
    }
 */
    &.--current,
    &:hover {
      // background-color: get-color(light-gray);
      border-left-color: get-color(black);
    }
  }

  &--uppercase &__option {
    text-transform: uppercase;
    font-weight: 700;
    font-family: $font-primary;
  }

  &.--extra-padding &__top {
    padding-inline: 16px;
  }
}
