.hero {
  background-color: get-color(black);
  //height: calc(100vh - 101px);

  &__container {
    padding: 65px 0;
    @include for-bp(tablet-lg) {
      padding: 100px 0;
    }
  }

  .italic {
    font-style: italic;
  }

  br.mobile {
    @include for-bp(tablet-lg) {
      display: none;
    }
  }

  &__title {
    color: get-color(white-2);
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 3.154px;

    > span {
      font-weight: 900;
    }
  }

  &__content {
    margin-top: 60px;
    color: get-color(medium-gray);
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    justify-content: center;
    gap: 60px;
    -webkit-box-align: center; /* Safari 3.1-6.0, Chrome 4-21 */
    -webkit-align-items: center; /* Safari 6.1+, Chrome 21+ */
    -ms-flex-align: center; /* IE 10 */
    align-items: center;
    flex-direction: column;
    @include for-bp(tablet-lg) {
      flex-direction: row;
      gap: 124px;
      margin-top: 120px;
      margin-left: 63px;
    }

    > .h-button {
      -webkit-order: 10;
      order: 10;
      background: get-color(blue);
      font-weight: bolder;
      padding: 16px 24px;
      border-radius: 10px;
      @include for-bp(tablet-lg) {
        white-space: nowrap;
        -webkit-order: 0;
        order: 0;
      }
    }
  }

  &__text {
    max-width: 547px;
    font-size: 2rem;
    @include for-bp(tablet-lg) {
      font-size: 2.4rem;
    }

    &::after {
      content: '';
      width: 194px;
      height: 1px;
      background-color: get-color(medium-gray);
      display: block;
      margin-top: 11px;
    }
  }
}
