.toast {
  position: fixed;
  border-radius: 4px;
  left: 50%;
  top: 30dvh;
  z-index: 50;
  color: get-color(black);
  padding: 15px 25px;
  box-shadow: 0 4px 12px #0000001a;
  -webkit-transform: translateX(-50%) translateY(-100vh);
  transform: translateX(-50%) translateY(-100vh);
  -webkit-transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  background: get-color(white);
  text-align: center;

  &.active {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
