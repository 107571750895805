.income-portfolio {
  background-color: get-color(black);
  color: get-color(light-gray);
  padding: 14px 0;
  padding-bottom: 34px;

  .swiper-slide {
    opacity: 0;
    -webkit-transition: opacity 0.8s ease-in-out;
    transition: opacity 0.8s ease-in-out;

    &.swiper-slide-active {
      opacity: 1;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 45px;
    height: 45px;
    color: get-color(gray-1);
    background-repeat: no-repeat;
    background-size: contain;
    @include for-bp(desktop-md) {
      -webkit-transform: translateY(calc(-50% - 45px));
      transform: translateY(calc(-50% - 45px));
    }

    &::after {
      content: none;
    }
  }

  --mobile-right-space: 16px;
  --desktop-spacing: 20px;
  @include for-bp(desktop-xlg) {
    --desktop-spacing: 50px;
    --mobile-right-space: 40px;
  }

  .swiper-button-next {
    top: 115px;
    // right: var(--mobile-right-space);
    background-image: url('../../assets/images/button-right.svg');
    -webkit-transition: 0.15s ease-in-out opacity;
    transition: 0.15s ease-in-out opacity;

    &:hover {
      opacity: 0.8;
    }

    @include for-bp(tablet-lg) {
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    @include for-bp(desktop-md) {
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      top: 50%;
    }
    @media (min-width: 1500px) {
      right: calc(((100% - #{$desktop-max-width}) / 2) - var(--desktop-spacing));
    }
  }

  .swiper-button-prev {
    top: 115px;
    right: calc(var(--mobile-right-space) + 70px);
    background-image: url('../../assets/images/button-left.svg');
    -webkit-transition: 0.15s ease-in-out opacity;
    transition: 0.15s ease-in-out opacity;

    &:hover {
      opacity: 0.8;
    }

    @include for-bp(tablet-md) {
      left: var(--mobile-right-space);
      right: auto;
    }
    @include for-bp(tablet-lg) {
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      right: auto;
      left: var(--mobile-right-space);
    }
    @include for-bp(desktop-md) {
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      right: auto;
      top: 50%;
    }
    @media (min-width: 1500px) {
      left: calc(((100% - #{$desktop-max-width}) / 2) - var(--desktop-spacing));
    }
  }

  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background-color: get-color(gray-1);
    opacity: 1;
    z-index: 10;
  }

  .swiper-pagination-bullet-active {
    width: 16px;
    height: 16px;
    background-color: get-color(white-6);
  }
}
