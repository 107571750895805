.finish {
  padding: 30px 10px 0;
  color: get-color(black);
  font-size: 30px;
  text-align: center;
  font-weight: 500;
  @include for-bp(mobile-md) {
    padding: 30px 30px 0;
  }
  @include for-bp(tablet-lg) {
    padding: 50px 100px 0;
  }
}
