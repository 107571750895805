.signature {
  margin: 80px auto 0;
  color: get-color(black);

  &__title {
    
    //text-align: center;
    margin-bottom: 40px;
    
  }

  &__firm {
    max-width: 100%;
    box-shadow: 10px 10px 40px 0 rgba(101, 141, 192, 0.4);
    border-radius: 20px;
    padding: 30px;
    color: get-color(blue-2);
    @include for-bp(tablet-lg) {
      width: 100%;
      max-width: 800px;
    }
  }

  &__doc {
    width: 100%;
    margin: auto;
  }

  &__top {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    gap: 20px;
    justify-content: center;

    &-button {
      display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
      display: -moz-box; /* Firefox 2.0-19 */
      display: -ms-flexbox; /* IE 10 */
      display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
      display: flex; /* Estándar */
      flex-direction: column;
      justify-content: space-between;
      -webkit-box-align: center; /* Safari 3.1-6.0, Chrome 4-21 */
      -webkit-align-items: center; /* Safari 6.1+, Chrome 21+ */
      -ms-flex-align: center; /* IE 10 */
      align-items: center;
      gap: 5px;
      width: 55px;

      &[aria-selected='true'] {
        color: get-color(gray-3);
        cursor: not-allowed;
      }
    }

    &-icon {
      width: 30px;
      height: 30px;
      fill: get-color(blue-2);
    }
  }

  &__container {
    padding-top: 30px;
  }

  &__type {
    &-input {
      font-family: $font-signature;
      width: 100%;
      text-align: center;
      border: none;
      border-bottom: 1px solid get-color(blue-2);
      font-size: 38px;
      padding: 15px 10px;

      @include for-bp(tablet-lg) {
        font-size: 48px;
      }

      &::placeholder {
        font-family: $font-signature;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .sigCanvas {
    display: block;
    margin: 0 auto;
    border: 1px solid get-color(gray-1);
    border-radius: 10px;
    max-width: 100%;
  }

  &__draw {
    &-footer {
      display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
      display: -moz-box; /* Firefox 2.0-19 */
      display: -ms-flexbox; /* IE 10 */
      display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
      display: flex; /* Estándar */
      justify-content: center;
      padding: 25px 0 0;
    }

    &-clear {
      font-size: 20px;
      -webkit-transition: all 0.4s;
      transition: all 0.4s;
      &:hover {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        font-weight: 700;
      }
    }
  }

  &__image {
    display: block;
    margin: 0 auto;
    padding: 20px 0 20px 30px;
    width: 100%;
    @include for-bp(tablet-switch) {
      width: auto;
      padding: 30px 0 30px 120px;
    }
  }
}
