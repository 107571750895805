.first-step {
  padding-top: 69px;

  &__title {
    font-weight: 700;
    text-align: center;
    font-size: 38px;
    margin-bottom: 60px;
    @include for-bp(tablet-lg) {
      margin-bottom: 90px;
    }
  }

  &__container {
    overflow: hidden;
  }

  &__list {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    justify-content: center;
    gap: 60px;
    margin: 0 auto;
    max-width: 528px;
    flex-wrap: wrap;
    @include for-bp(tablet-lg) {
      max-width: 1020px;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

  &__card {
    font-family: $font-secondary;
    padding: 36px 30px;
    border-radius: 6px;
    border: 2px solid get-color(blue-2);
    box-shadow: 2px -1px 9px 0px rgba(0, 0, 0, 0.2);
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    flex-direction: column;
    gap: 30px;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
    -webkit-animation: cardGetIn 0.6s forwards;
    animation: cardGetIn 0.6s forwards;
    @include for-bp(desktop-md) {
      flex-grow: 1;
      flex-basis: 0;
    }

    @keyframes cardGetIn {
      to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
    }

    &-title {
      color: get-color(blue-2);
      text-transform: uppercase;
      font-family: $font-secondary;
      font-size: 2rem;
      letter-spacing: 0.56px;
      font-weight: 500;
    }

    &-text {
      color: black;
      font-size: 16px;
      font-family: $font-primary;
      letter-spacing: 0.25px;
    }
  }
}
