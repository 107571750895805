.footer {
  display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
  display: -moz-box; /* Firefox 2.0-19 */
  display: -ms-flexbox; /* IE 10 */
  display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
  display: flex; /* Estándar */
  flex-direction: column;
  gap: 23px;
  background-color: get-color(white-2);
  // height: $header-height;
  border-top: 1px solid get-color(gray-3);
  color: get-color(black);
  padding: 23px 0;

  @include for-bp(tablet-lg) {
    // height: $header-height-desktop;
  }

  > .h-wrapper {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    justify-content: space-between;
    -webkit-box-align: center; /* Safari 3.1-6.0, Chrome 4-21 */
    -webkit-align-items: center; /* Safari 6.1+, Chrome 21+ */
    -ms-flex-align: center; /* IE 10 */
    align-items: center;
    height: 100%;
    gap: 10px;

    > a {
      line-height: 1;
    }
  }

  &__logo {
    color: get-color(black);
    // height: 46px;
    width: 180px;
    max-width: 100%;
    @include for-bp(mobile-md) {
      width: 200px;
    }
  }

  &__buttons {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    flex-direction: column;
    @include for-bp(mobile-md) {
      flex-direction: row;
    }
  }

  &__button {
    text-transform: uppercase;
    text-align: right;
    font-size: 1.4rem;
    color: get-color(black);
    -webkit-transition: 0.15s ease-in-out color;
    transition: 0.15s ease-in-out color;
    padding-top: 4px;

    @include for-bp(mobile-md) {
      padding: 7px 19px;
      text-align: left;
      border-right: 1px solid get-color(black);
    }

    @include for-bp(tablet-switch) {
      font-size: 1.6rem;
      padding: 7px 31px;
    }

    &:hover {
      color: get-color(gray-3);
    }

    &:first-child {
      text-align: right;

      width: 120px;
      @include for-bp(tablet-switch) {
        width: auto;
      }
    }

    &:only-child {
      border-right: none;
    }

    &:last-child {
      border-right: 0;
      padding-right: 0;
      // width: 75px;
      @include for-bp(tablet-switch) {
        width: auto;
      }
    }
  }

  &__disclaimer {
    background-color: get-color(white-2);
    padding: 0 0;
    padding-top: 0;
    width: 100%;
    display: block;

    &-content {
      font-size: 12px;
      color: get-color(gray-3);
      margin-left: auto;
      margin-right: auto;
      padding: 0 22px;
      max-width: 1440px;

      @media (min-width: 1240px) {
        padding-left: 70px;
        padding-right: 70px;
      }
    }

    &-button {
      color: black;
      text-decoration: underline;
      -webkit-transition: 0.15s ease-in-out color;
      transition: 0.15s ease-in-out color;

      &:hover {
        color: get-color(blue);
      }
    }
  }
}
