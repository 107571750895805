.organization2 {
  z-index: 1;

  &__container {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    flex-direction: column;
    margin-bottom: 50px;

    > div:first-child hr {
      display: none;
    }
  }

  &__buttons {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    gap: 30px;
    -webkit-box-align: center; /* Safari 3.1-6.0, Chrome 4-21 */
    -webkit-align-items: center; /* Safari 6.1+, Chrome 21+ */
    -ms-flex-align: center; /* IE 10 */
    align-items: center;
  }

  hr {
    margin: 50px 20px;
  }

  &__max {
    color: get-color(black);
    display: block;
  }

  &__instructions {
    color: get-color(red);
    margin: 20px 0;
    display: block;
    font-size: 1.6rem;
  }
}
