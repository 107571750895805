.faqs {
  padding: 50px 0;
  color: get-color(blue-2);

  @include for-bp(tablet-lg) {
    padding: 120px 0;
  }

  &__title {
    color: get-color(black);
    text-align: center;
    margin-bottom: 5px;
  }

  &__text {
    text-align: center;
    font-size: 20px;

    a {
      padding-bottom: 4px;
      border-bottom: 1px solid get-color(blue-2);
      -webkit-transition: 0.15s ease-in-out color;
      transition: 0.15s ease-in-out color;

      &:hover {
        color: get-color(blue-1);
      }
    }
  }

  &__list {
    margin-top: 60px;
  }

  &__item {
    overflow: hidden;
  }

  &__arrow {
    width: 26px;
    height: 26px;
    -webkit-transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;
  }

  &__question {
    font-family: $font-secondary;
    cursor: pointer;
    padding: 20px 16px;
    text-transform: uppercase;
    border-bottom: 1px solid get-color(gray-1);
    font-weight: 400;
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    justify-content: space-between;
    -webkit-transition: border-color 0.5s;
    transition: border-color 0.5s;
  }

  &__question-button {
    width: 30px;
    height: 30px;
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    justify-content: center;
    -webkit-box-align: center; /* Safari 3.1-6.0, Chrome 4-21 */
    -webkit-align-items: center; /* Safari 6.1+, Chrome 21+ */
    -ms-flex-align: center; /* IE 10 */
    align-items: center;
  }

  &__question[aria-expanded='true'] {
    border-color: transparent;
  }

  &__question[aria-expanded='true'] &__arrow {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  &__answer {
    display: grid;
    max-height: 0;
    -webkit-transition: all 0.5s cubic-bezier(0, 1, 0, 1);
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);

    &-container {
      padding: 10px 16px 20px;
      border-bottom: 1px solid get-color(gray-1);
    }

    &.--show {
      max-height: 999px;
      height: auto;
      //padding-bottom: 30px;
      -webkit-transition: all 0.5s cubic-bezier(1, 0, 1, 0);
      transition: all 0.5s cubic-bezier(1, 0, 1, 0);
    }
  }
}
