.h-wrapper {
  max-width: $desktop-max-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: $mobile-bleed;
  padding-right: $mobile-bleed;
  width: 100%;

  @include for-bp('tablet-sm') {
    padding-left: $tablet-bleed;
    padding-right: $tablet-bleed;
  }

  @include for-bp('desktop-md') {
    padding-left: $desktop-bleed;
    padding-right: $desktop-bleed;
  }
}
