.deposit {
  font-family: $font-secondary;
  padding: 0 10px;
  @include for-bp(mobile-md) {
    padding: 0 30px;
  }
  @include for-bp(tablet-lg) {
    padding: 0 100px;
  }

  &__input {
    &-field {
      max-width: 295px;
      width: 100%;
    }
  }

  &__content {
    margin-bottom: 99px;
  }

  &__text {
    color: get-color(blue-2);
    font-size: 20px;
    letter-spacing: -0.38px;
    font-weight: 300;
  }

  &__title {
    color: get-color(black);
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 12px;
    letter-spacing: -0.456px;
  }
}
