.email-verification {
  text-align: center;
  color: get-color(blue-2);
  display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
  display: -moz-box; /* Firefox 2.0-19 */
  display: -ms-flexbox; /* IE 10 */
  display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
  display: flex; /* Estándar */
  justify-content: space-between;
  flex-direction: column;
  min-height: calc(100vh - 2 * #{$header-height});

  &.--small-design {
    min-height: 0;
    .email-verification {
      &__content {
        display: block;

        h2 {
          font-size: 2.4rem;
          @include for-bp(tablet-lg) {
            font-size: 3rem;
          }
        }
      }

      &__buttons {
        margin-top: 50px;
      }
    }
  }

  &__content {
    flex-grow: 1;
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    flex-direction: column;
    padding: 55px 0;

    h2 {
      font-size: 24px;
    }
  }

  &__instructions {
    font-size: 20px;
  }

  &__container {
    justify-content: center;
    margin-top: 40px;
    gap: 15px;
  }

  &__buttons {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    justify-content: center;
    -webkit-box-align: center; /* Safari 3.1-6.0, Chrome 4-21 */
    -webkit-align-items: center; /* Safari 6.1+, Chrome 21+ */
    -ms-flex-align: center; /* IE 10 */
    align-items: center;
    margin-top: 100px;
    flex-direction: column;
    gap: 15px;
  }

  &__input {
    text-transform: uppercase;
    aspect-ratio: 1;
    width: 25px !important;
    border-top: none;
    border-left: none;
    border-right: none;
    @include for-bp(tablet-switch) {
      width: 35px !important;
    }
  }
}
