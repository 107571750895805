.icon-list {
  // background-color: get-color(light-gray);
  color: get-color(black);
  padding-bottom: 34px;

  &__container {
    padding: 50px 0;
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    gap: 40px;
    justify-content: center;
    margin: 0 auto;
    max-width: 1100px;
    flex-wrap: wrap;
    @include for-bp(tablet-lg) {
      justify-content: space-between;
    }
  }

  &__item {
    max-width: 287px;
    text-align: center;

    &-title {
      color: get-color(blue-2);
      margin-bottom: 12px;
      text-transform: uppercase;
      font-family: $font-secondary;
      font-size: 2rem;
      letter-spacing: 0.56px;
      font-weight: 500;
      text-align: left;
    }

    &-image {
      margin-bottom: 27px;
    }

    &-text {
      text-align: left;
    }
  }

  &__message {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    justify-content: center;
    -webkit-box-align: center; /* Safari 3.1-6.0, Chrome 4-21 */
    -webkit-align-items: center; /* Safari 6.1+, Chrome 21+ */
    -ms-flex-align: center; /* IE 10 */
    align-items: center;
    gap: 8px;
    flex-flow: row wrap;

    &-text {
      padding-top: 7px;
    }

    &-img {
      max-width: 200px;
      width: 100%;
    }
  }
}
