.trust {
  z-index: 1;
  &__grid {
    @include for-bp(tablet-lg) {
      -webkit-column-gap: 70px;
      column-gap: 70px;
    }
  }

  &__dropdown {
    width: 181px;
    max-width: 100%;
  }

  .span-2-xs {
    grid-column: span 2;
    @include for-bp(mobile-md) {
      grid-column: unset;
    }
  }
}
