.user-info {
  font-family: $font-secondary;
  padding: 35px 0 0;
  @include for-bp(tablet-switch) {
    padding: 55px 25px 0 25px;
  }

  &__title {
    letter-spacing: 1.064px;
    text-align: center;
    font-weight: 600;
    color: get-color(black);
    margin-bottom: 50px;
    font-size: 24px;
    @include for-bp(tablet-switch) {
      // margin-bottom: 100px;
      margin-bottom: 50px;
      font-size: 24px;
    }
  }

  &__form {
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
  }

  &__input{
    width: 100%;

    &--name {
      padding-bottom: 20px;
    }

    &--password {
      padding-bottom: 20px;
      @include for-bp(tablet-switch) {
        padding-bottom: 0;
      }
    }
  }

  &__password {
    padding-bottom: 10px;
  }

  &__password-inputs {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    flex-flow: column wrap;
    gap: 0;

    @include for-bp(tablet-switch) {
      flex-flow: row nowrap;
      gap: 20px;
    }
  }

  &__description {
    font-family: $font-primary;
    color: get-color(blue-2);
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    align-self: flex-end;

    &.--error {
      color: get-color(red);
    }

    &.--valid {
      color: get-color(green);
    }
  }

  &__email {
    margin-top: 20px;
    @include for-bp(tablet-switch) {
      margin-top: 0;
    }
  }
}
