.financial {
  font-family: $font-secondary;
  padding: 30px 10px 0;
  @include for-bp(mobile-md) {
    padding: 30px 30px 0;
  }
  @include for-bp(tablet-lg) {
    padding: 50px 100px 0;
  }

  &__input {
    &-field {
      max-width: 295px;
      width: 100%;
    }
  }

  &__content {
    margin-bottom: 50px;
  }

  &__questions {
    list-style-type: none;
    padding: 0;
    color: get-color(blue-2);
    font-weight: 300;
  }

  &__question {
    margin-bottom: 40px;
    font-size: 20px;
    @include for-bp(tablet-switch) {
      margin-bottom: 20px;
    }

    &-label {
      display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
      display: -moz-box; /* Firefox 2.0-19 */
      display: -ms-flexbox; /* IE 10 */
      display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
      display: flex; /* Estándar */
      gap: 15px;
      flex-direction: column;

      @include for-bp(tablet-switch) {
        flex-direction: row;
      }
    }

    &-input {
      border: none;
      border-bottom: 1px solid get-color((blue-2));
      text-align: center;
      outline: none;
    }

    &-note {
      margin-top: 10px;
      @include for-bp(tablet-switch) {
        margin-top: 0;
      }
    }
  }

  &__text {
    color: get-color(blue-2);
    font-size: 20px;
    letter-spacing: -0.38px;
    font-weight: 300;
  }

  &__title {
    color: get-color(black);
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 12px;
    letter-spacing: -0.456px;
  }

  &__alert {
    height: 35px;
  }
}


