:root {
    --blue: #102DA9;
    --black: #000000;
    --white: #FFFFFF;
    --dark-gray: #A8A8A8;
    --medium-gray: #DADADA;
    --light-gray: #EFEFEF;
    --error: #FF6363;
}

.color-blue {
    color: var(--blue);
}

::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    background: var(--light-gray);
}
::-webkit-scrollbar-thumb {
    background: var(--blue);
}