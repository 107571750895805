.goals {
  color: get-color(black);
  padding: 50px 0;
  @include for-bp(tablet-lg) {
    padding: 100px 0;
  }

  &.animate &__image-container {
    -webkit-transform: none;
    transform: none;
  }

  &__image-container {
    -webkit-transform: translateX(-100vh);
    transform: translateX(-100vh);
    -webkit-transition: all 1.3s;
    transition: all 1.3s;
    max-width: 392px;
    width: 100%;
    @include for-bp(tablet-lg) {
      max-width: none;
    }
  }

  &__content {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    flex-direction: column;
    justify-content: center;
    gap: 22px;
    max-width: 405px;
    text-align: center;
    @include for-bp(tablet-lg) {
      text-align: left;
    }
  }

  &__container {
    display: grid;
    gap: 40px;
    place-items: center;
    @include for-bp(tablet-lg) {
      gap: 200px;
      place-items: normal;
      grid-template-columns: 1fr 1fr;
    }
  }

  &__text {
    margin-bottom: 26px;
    font-size: 20px;
    font-weight: 300;
  }

  &__title {
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 24px;
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    flex-direction: column;
    text-transform: uppercase;
    line-height: 32px;
    @include for-bp(tablet-switch) {
      font-size: 38px;
    }

    span:first-child {
      font-weight: 600;
      font-size: 30px;
      letter-spacing: 7.15px;
      @include for-bp(tablet-switch) {
        font-size: 50px;
      }
    }

    span:nth-child(2) {
      letter-spacing: 5.244px;
      margin-top: 12px;
      display: block;

      @include for-bp(mobile-md) {
        margin-top: 10px;
      }

    }

    span:last-child {
      letter-spacing: 12.064px;
      margin-top: 8px;
      display: block;

      @include for-bp(tablet-switch) {
        letter-spacing: 20.064px;
      }

      @include for-bp(mobile-md) {
        margin-top: 6px;
      }
    }
  }

  &__image-m {
    @include for-bp(tablet-lg) {
      display: none;
    }
  }

  &__image-d {
    display: none;
    @include for-bp(tablet-lg) {
      display: block;
    }
  }

  &__button {
    width: fit-content;
    margin: 0 auto;
    @include for-bp(tablet-lg) {
      margin: 0;
    }
  }

  .goals__button {
    background-color: get-color(blue);
    padding: 16px 24px;
    font-weight: 700;
  }
}
