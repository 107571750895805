.open-account {
  &__form {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    // justify-content: space-between;
    flex-direction: column;
    min-height: calc(100vh - 2 * #{$header-height});

    &.--show-errors .--empty:not(.optional)::placeholder,
    &.--show-errors .--error::placeholder,
    &.--show-errors .dropdown:not(.optional).--empty .dropdown__header {
      color: get-color(red);
    }
  }

  &--align-middle {
    // flex-grow: 1;
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    flex-direction: column;
    justify-content: center;
    padding-top: 60px;
  }

  &__buttons {
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    justify-content: space-between;
    gap: 20px;

    .h-form-button {
      -webkit-transition: all .5s ease;
      transition: all .5s ease;
      position: relative;
    }

    .h-form-button span {
      -webkit-transition: all .25s ease;
      transition: all .25s ease;
    }

    &-container {
      padding: 40px 0;
      flex-grow: 1;
      display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
      flex-direction: column;
      justify-content: center;
      @include for-bp(tablet-switch) {
        padding: 60px 0;
      }
    }
  }
}
