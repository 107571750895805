.input-field {
  display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    -webkit-box-align: center; /* Safari 3.1-6.0, Chrome 4-21 */
    -webkit-align-items: center; /* Safari 6.1+, Chrome 21+ */
    -ms-flex-align: center; /* IE 10 */
    align-items: center;
  gap: 22px;
  position: relative;
  font-family: $font-secondary;

  &--error::placeholder {
    color: get-color('red');
  }

  &__placeholder-wrapper {
    padding-top: 10px;
    position: relative;
    width: 100%;
    font-weight: 300;
  }

  &__placeholder {
    position: absolute;
    left: 16px;
    top: 20px;
    -webkit-transition: 0.3s ease all;
    transition: 0.3s ease all;
    color: get-color(dark-gray);
    pointer-events: none;
  }

  &__placeholder.--has-value,
  &__input:focus + &__placeholder {
    -webkit-transform: translateY(-32px) translateX(-12px);
    transform: translateY(-32px) translateX(-12px);
  }
  &__show-pass {
    position: absolute;
    top: calc(50% + 5px);
    -webkit-transform: translateY(-50% + 5);
    transform: translateY(-50% + 5);
    right: 10px;
    width: 20px;
  }

  &__instructions {
    color: get-color(blue-2);
    font-weight: 300;
    font-size: 2rem;
  }

  &__error {
    color: get-color(red);
    position: absolute;
    bottom: -20px;
  }

  &__input {
    background-color: get-color(light-gray);
    padding: 0 16px;
    height: 41px;
    border-radius: 6px;
    border: 1px solid get-color(medium-gray);
    width: 100%;
    font-family: $font-secondary;
    font-weight: 300;
    color: #000;
    outline: none;
    display: -webkit-box; /* Safari 3.1-6.0, Chrome 4-21 */
    display: -moz-box; /* Firefox 2.0-19 */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Safari 6.1+, Chrome 21+ */
    display: flex; /* Estándar */
    -webkit-transition: border 250ms ease-in-out;
    transition: border 250ms ease-in-out;

    &::placeholder {
      text-transform: uppercase;
      font-family: $font-secondary;
      color: get-color(dark-gray);
    }
    &:focus {
      border-color: get-color(dark-gray);
    }
    &:focus::placeholder {
      color: get-color(dark-gray);
    }
  }

  input[type='date']:before {
    content: attr(placeholder) !important;
    color: get-color(black);
    margin-right: 0.5em;
    @include for-bp(tablet-switch) {
      content: none !important;
    }
  }

  input[type='date']:focus:before,
  input[type='date']:valid:before {
    content: '';
    @include for-bp(tablet-switch) {
      content: none !important;
    }
  }
}

