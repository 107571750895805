.h-input {
  background-color: get-color(light-gray);
  padding: 0 16px;
  height: 41px;
  border-radius: 6px;
  border: 1px solid get-color(medium-gray);
  color: #000;
  width: 100%;
  font-family: $font-secondary;
  font-weight: 300;
  outline: none;
  -webkit-transition: border 250ms ease-in-out;
  transition: border 250ms ease-in-out;

  &::placeholder {
    color: get-color(dark-gray);
  }

  &:focus {
    border-color: get-color(dark-gray);
  }

  @include for-bp(tablet-lg) {
    font-size: 1.6rem;
  }
}
