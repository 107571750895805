h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  font-family: $font-secondary;
  margin-top: 0;
}

h1,
.h1 {
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 155%;
  letter-spacing: 2.204px;
  @include for-bp(tablet-lg) {
    font-size: 3.8rem;
  }

  > span {
    font-weight: 450;
  }
}

h2,
.h2 {
  font-size: 3.2rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 5.13px;
  text-transform: uppercase;
  @include for-bp(tablet-lg) {
    font-size: 3.8rem;
  }
}

h3,
.h3 {
  font-size: 24px;
  font-weight: 400;
  line-height: 130%;
}

h4,
.h4 {
  font-size: 21px;
  font-weight: 500;
  line-height: 130%;
}

p {
  margin: 0;
  letter-spacing: 0.25px;
}
